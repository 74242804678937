var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":true,"width":"500px"},on:{"click:outside":function($event){return _vm.$emit('cancel')}}},[_c('base-material-card',{staticClass:"pa-8",attrs:{"header":false}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[(!_vm.entityId)?_c('validation-provider',{attrs:{"name":"Entity ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Entity name","items":_vm.entities,"item-text":"name","item-value":"id","loading":!_vm.ready,"error-messages":errors},model:{value:(_vm.entityPart.entityId),callback:function ($$v) {_vm.$set(_vm.entityPart, "entityId", $$v)},expression:"entityPart.entityId"}})]}}],null,true)}):_vm._e(),_c('div',[_c('validation-provider',{attrs:{"rules":{
              required: true,
              min_value: 1,
            },"name":"Quantity"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Number parts to add","type":"number","min":"0","error-messages":errors},model:{value:(_vm.entityPart.quantity),callback:function ($$v) {_vm.$set(_vm.entityPart, "quantity", _vm._n($$v))},expression:"entityPart.quantity"}})]}}],null,true)})],1),_c('v-row',{staticClass:"justify-end mt-4"},[_c('v-btn',{staticClass:"grey mr-2",on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"primary",attrs:{"type":"submit","loading":_vm.requesting,"disabled":invalid}},[_vm._v(" Add ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }