<template>
  <v-dialog
    :value="true"
    width="800px"
    @click:outside="$emit('cancel')"
  >
    <base-material-card
      v-if="ready"
      icon="mdi-nut"
      :title="$t('c.cartTitle')"
      class="px-5 py-3"
    >
      <v-card-title>{{ $t('c.cartTitle') }}</v-card-title>
      <validation-observer
        ref="observer"
        v-slot="{ invalid }"
      >
        <v-form
          @submit.prevent="updatePart"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-textarea
                v-model="copy.name"
                class="purple-input"
                auto-grow
                rows="1"
                :label="$t('name') | capitalize"
              />
            </v-col>

            <v-col
              class="align-self-end"
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="copy.model_identifier"
                class="purple-input"
                :label="$t('model') | capitalize"
                :error-messages="errors.model_identifier"
              />
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-textarea
                v-model="copy.description"
                class="purple-input"
                auto-grow
                rows="1"
                persistent-hint
                :hint="$t('c.description.hint')"
                :label="$t('description') | capitalize"
                :error-messages="errors.category_id"
              />
            </v-col>
            <v-col
              class="align-self-end"
              cols="12"
              md="6"
            >
              <v-select
                v-model="copy.category_id"
                :items="categories"
                :label="$t('category') | capitalize"
                item-text="description"
                item-value="value"
                :error-messages="errors.category_id"
              >
                <template v-slot:prepend-item>
                  <div
                    class="pa-1 ma-1 "
                    style="cursor: pointer"
                    @click="showNewCategoryDialog = true"
                  >
                    <span>
                      <v-icon> mdi-plus</v-icon>
                      <span> {{ $t('c.newCategory') | capitalize }} </span>
                    </span>
                  </div>

                  <v-divider />
                </template>
              </v-select>
            </v-col>
          </v-row>

          <v-row class="">
            <v-col class="d-flex justify-end">
              <v-btn
                class="mr-4"
                type="button"
                :loading="requesting"
                :disabled="invalid"
                @click="$emit('cancel')"
              >
                {{ $t('cancel') | capitalize }}
              </v-btn>
              <v-btn
                class="primary"
                type="submit"
                :loading="requesting"
                :disabled="invalid"
              >
                {{ $t('save') | capitalize }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </base-material-card>
    <v-dialog
      v-if="showNewCategoryDialog"
      v-model="showNewCategoryDialog"
      width="500px"
    >
      <v-card>
        <v-card-title
          class="green darken-1 grey--text  text--lighten-4 pa-4 mb-4"
        >
          {{ $t('c.newCategory') | capitalize }}
        </v-card-title>
        <create-category-form
          class="pa-4"
          @cancel="showNewCategoryDialog=false"
          @categoryCreated="handleCategoryCreated"
        />
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import CategoriesRepository from '@/api/CategoriesRepository'
import clone from 'clone'
import PartsRepository from '@/api/PartsRepository'
import { parseErrors } from '@/helpers/helpers'
export default {
  name: 'PartEdit',
  props: {
    part: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      copy: clone(this.part),
      file: null,
      requesting: false,
      categories: null,
      showNewCategoryDialog: false,
      ready: false,
      errors: {
        name: null,
        model_identifier: null,
        description: null,
        category_id: null,
      },
    }
  },
  created () {
    CategoriesRepository.index()
      .then(({ data }) => {
        this.categories = data
        this.ready = true
      })
      .catch(() => {

      })
  },
  methods: {

    handleCategoryCreated (category) {
      this.categories.push(category)
      this.categories = this.categories.sort((c1, c2) => {
        return c1.description > c2.description ? 1 : -1
      })
      this.categoryId = category.value

      this.showNewCategoryDialog = false
    },
    updatePart () {
      const body = {
        name: this.copy.name,
        model_identifier: this.copy.model_identifier,
        description: this.copy.description,
        category_id: this.copy.category_id,
      }
      this.requesting = true
      PartsRepository.update(this.part.id, body)
        .then(({ data }) => {
          this.$emit('partUpdated', {
            name: data.name,
            description: data.description,
            category: data.category,
            modelIdentifier: data.model_identifier,

          })
        })
        .catch(response => {
          this.requesting = false
          if (response.status === 422) {
            this.errors = parseErrors(response)
          }
        })
    },
  },
  i18n: {
    messages: {
      en: {
        c: {
          cartTitle: 'New Part',
          cardSubTitle: 'Part Description',
          description: {
            hint: 'Press enter now new line',
          },
          quantitiesTitle: 'Parts Quantity',
          newCategory: 'New Category',
          addEntity: 'Associate entity',
        },
      },
      it: {
        c: {
          cartTitle: 'Nuova Part',
          cardSubTitle: 'Parte descrizione',
          description: {
            hint: 'Premere invio ora nuova riga',
          },
          quantitiesTitle: 'Quantità di parti',
          newCategory: 'Categoria nuova',
          addEntity: 'Entità associata',

        },
      },

    },
  },
}
</script>

<style scoped>

</style>
