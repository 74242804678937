<template>
  <v-container
    v-if="part"
    id="regular-tables"
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
      >
        <base-material-card
          icon="mdi-nut"
          class="px-5 py-3 relative"
        >
          <seen-by :roles-ids="[$root.$data.rolesIds.stockManager]">
            <v-btn
              class="absolute top-20 right-20"
              @click="editPart = true"
            >
              Edit
            </v-btn>
          </seen-by>
          <div
            class="parent mt-8 d-flex "
          >
            <div
              class="section super-centered"
            >
              <div class="part-image-wrapper">
                <v-img
                  :src="part.image"
                  class="part-image"
                  width="350px"
                  height="350px"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(222,222,222,.5)"
                  contain
                />
                <div class="part-image-action">
                  <v-btn
                    class="part-image-btn"
                    fab
                    small
                    @click="editPartImage = true"
                  >
                    <v-icon
                      class="part-image-icon"
                    >
                      mdi-camera
                    </v-icon>
                  </v-btn>
                </div>
                <part-image-edit
                  v-if="editPartImage"
                  :part="part"
                  @cancel="editPartImage= false"
                  @imageUpdated="handleImageUpdated"
                />
              </div>
            </div>
            <v-row class="flex-column flex-lg-row pl-4 py-2 section">
              <v-col>
                <h2
                  class="blue--text"
                  v-text="part.name"
                />
                <p
                  class="grey--text"
                  v-text="part.category.name"
                />
                <p
                  style="min-height: 75px; max-width: 450px; text-wrap: normal;"
                  v-text="part.description"
                />
                <h3 class="font-weight-light">
                  <span class="font-weight-bold"> Stock: </span> {{ stockIn }}
                </h3>
              </v-col>

              <seen-by :roles-ids="[$root.$data.rolesIds.stockManager]">
                <v-col class="section grey lighten-5 py-2 pl-4">
                  <v-simple-table
                    class="mb-2"
                    style="max-height: 700px; overflow-y: scroll"
                  >
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class="text-left">
                          Entity
                        </th>
                        <th class="text-left">
                          Parts
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                        v-for="entityPart in part.entity_parts"
                        :key="entityPart.id"
                        @click="handleClickEntityRow(entityPart.entity.id)"
                      >
                        <td>{{ entityPart.entity.name }}</td>
                        <td>{{ entityPart.stock_in - entityPart.stock_missing }}</td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-btn
                    class="primary"
                    block
                    @click="showAddPartsDialog = true"
                  >
                    Add parts to entity
                  </v-btn>
                  <add-part-or-update-stock
                    v-if="showAddPartsDialog"
                    :part="part"
                    :entity-parts="part.entity_parts"
                    :entity-id="entityClicked"
                    @cancel="handleCancel"
                  />
                  <part-edit
                    v-if="editPart"
                    :part="part"
                    @cancel="editPart=false"
                    @partUpdated="handlePartUpdated"
                  />
                </v-col>
              </seen-by>
            </v-row>
          </div>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import PartsRepository from '@/api/PartsRepository'
import { stockInCounter } from '@/helpers/PartsHelpers'
import AddPartOrUpdateStock from '@/views/dashboard/pages/ShowPart/components/AddPartOrUpdateStock'
import EventBus from '@/plugins/EventBus'
import SeenBy from '@/views/dashboard/components/SeenBy'
import PartEdit from '@/views/dashboard/pages/PartEdit/PartEdit'
import PartImageEdit from '@/views/dashboard/pages/ShowPart/components/PartImageEdit'

export default {
  name: 'PartShow',
  components: { PartImageEdit, PartEdit, SeenBy, AddPartOrUpdateStock },
  props: {
    partId: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Number | String,
      required: true,
    },
  },
  data () {
    return {
      showAddPartsDialog: false,
      part: null,
      entityClicked: null,
      editPart: false,
      editPartImage: false,
    }
  },
  computed: {
    stockIn () {
      return stockInCounter(this.part)
    },
  },
  created () {
    this.getPart()
  },
  mounted () {
    const instance = this
    EventBus
      .$on('addedStockToEntityPart',
        ({ entityId, addedStock }) => {
          const entityPart = instance.part.entity_parts.find(e => e.entity_id === entityId)
          entityPart.stock_in += addedStock
          instance.entityClicked = null
          instance.showAddPartsDialog = false
        })

    EventBus
      .$on('createdEntityPart',
        ({ entityPart }) => {
          instance.part.entity_parts.push(entityPart)
          instance.showAddPartsDialog = false
        })
  },
  methods: {
    getPart () {
      PartsRepository.show(this.partId, {
        with: 'entityParts.entity',
      })
        .then(({ data }) => {
          this.part = data
        })
        .catch(() => {

        })
    },
    handleClickEntityRow (entityId) {
      this.entityClicked = entityId
      this.showAddPartsDialog = true
    },
    handleCancel () {
      this.showAddPartsDialog = false
      this.entityClicked = null
    },
    handleImageUpdated ({ image, thumb }) {
      this.part.image = image
      this.part.thumb = thumb
      this.editPartImage = false
    },
    handlePartUpdated ({ name, description, category, modelIdentifier }) {
      this.part.name = name
      this.part.description = description
      this.part.category = category
      this.part.model_identifier = modelIdentifier
      this.editPart = false
    },
  },
}
</script>

<style scoped>

.parent {
  display: grid;
  grid-template-columns:  minmax(350px, 25%) 1fr;
}

.part-image-wrapper {
  position: relative;
}

.part-image-action {
  position: absolute;
  top: 5px;
  right: 5px;
}

.part-image-btn {
  color: #999999;
}

.part-image-btn .part-image-icon {
  color: #E6E6E6;
}
</style>
