<template>
  <v-dialog
    :value="true"
    width="500px"
    @click:outside="$emit('cancel')"
  >
    <v-card>
      <v-card-title>
        {{ $t('c.title') }}
      </v-card-title>
      <v-card-text>
        <v-col>
          <v-file-input
            v-model="file"
            accept="image/*"
            show-size
            :hint="$t('c.hint')"
            persistent-hint
            :label="$t('image')"
            :error-messages="errors.image"
            @change="onFileChange"
          />
        </v-col>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          class="mr-4"
          type="button"
          :loading="requesting"
          @click="$emit('cancel')"
        >
          {{ $t('cancel') | capitalize }}
        </v-btn>
        <v-btn
          class="primary"
          type="button"
          :loading="requesting"
          @click="saveImage"
        >
          {{ $t('save') | capitalize }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import PartsRepository from '@/api/PartsRepository'
import { parseErrors } from '@/helpers/helpers'

export default {
  name: 'PartImageEdit',
  props: {
    part: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      file: null,
      requesting: false,
      errors: {
        image: null,
      },
    }
  },
  methods: {
    onFileChange (file) {
      this.image = file
    },

    saveImage () {
      this.requesting = true
      const formData = new FormData()
      formData.append('image', this.image)
      PartsRepository.replaceImage(this.part.id, formData)
        .then(data => {
          this.$emit('imageUpdated', {
            image: data.data.image,
            thumb: data.data.thumb,
          })
        })
        .catch(({ response }) => {
          this.requesting = false
          if (response.status === 422) {
            this.errors = parseErrors(response)
          }
        })
    },
  },
  i18n: {
    messages: {
      en: {
        c: {
          title: 'Editing Part Image',
          hint: 'Part image will be replaced',
        },
      },
      it: {
        c: {
          title: 'Modifica dell\'immagine',
          hint: 'L\'immagine della parte verrà sostituita',
        },
      },
    },
  },
}
</script>

<style scoped>

</style>
