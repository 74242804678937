<template>
  <v-dialog
    :value="true"
    width="500px"
    @click:outside="$emit('cancel')"
  >
    <base-material-card
      class="pa-8"
      :header="false"
    >
      <validation-observer
        ref="observer"
        v-slot="{ invalid }"
      >
        <v-form
          @submit.prevent="submit"
        >
          <validation-provider
            v-if="!entityId"
            v-slot="{ errors }"
            name="Entity "
            rules="required"
          >
            <!-- TODO add validation to select entity only once -->
            <v-select
              v-model="entityPart.entityId"
              label="Entity name"
              :items="entities"
              item-text="name"
              item-value="id"
              :loading="!ready"
              :error-messages="errors"
            />
          </validation-provider>

          <div>
            <validation-provider
              v-slot="{ errors }"
              :rules="{
                required: true,
                min_value: 1,
              }"
              name="Quantity"
            >
              <v-text-field
                v-model.number="entityPart.quantity"
                label="Number parts to add"
                type="number"
                min="0"
                :error-messages="errors"
              />
            </validation-provider>
          </div>
          <v-row class="justify-end mt-4">
            <v-btn
              class="grey mr-2"

              @click="$emit('cancel')"
            >
              Cancel
            </v-btn>
            <v-btn
              class="primary"
              type="submit"
              :loading="requesting"
              :disabled="invalid"
            >
              Add
            </v-btn>
          </v-row>
        </v-form>
      </validation-observer>
    </base-material-card>
  </v-dialog>
</template>

<script>

import EntitiesRepository from '@/api/EntitiesRepository'
import { ValidationProvider, extend } from 'vee-validate'
// eslint-disable-next-line camelcase
import { min_value } from 'vee-validate/dist/rules'
import EntityPartsRepository from '@/api/EntityPartsRepository'
import EventBus from '@/plugins/EventBus'

extend('min_value', min_value)
export default {
  name: 'AddPartOrUpdateStock',
  components: {
    ValidationProvider,
  },
  props: {
    // the entities that already this part
    entityParts: {
      type: Array,
      required: true,
    },
    entityId: {
      type: Number,
      default: () => null,
    },
    part: {
      type: Object,
      required: true,
    },
  },

  data () {
    return {
      entities: [],
      entityPart: {
        entityId: null,
        quantity: null,
      },
      ready: false,
      requesting: false,
    }
  },
  computed: {
    entitiesIds () {
      return this.entityParts.map(e => e.entity_id)
    },
    entityHasPart () {
      return this.entitiesIds.includes(this.entityPart.entityId)
    },
  },
  created () {
    if (this.entityId) {
      this.entityPart.entityId = this.entityId
      this.ready = true
    } else {
      EntitiesRepository.list()
        .then(({ data }) => {
          this.entities = data
          this.ready = true
        })
    }
  },
  methods: {
    submit () {
      this.$refs.observer.validate().then(success => {
        if (success) {
          this.checkCreateOrUpdate()
        }
      })
    },
    checkCreateOrUpdate () {
      if (this.entityHasPart) {
        this.updateEntityPart()
      } else {
        this.createEntityPart()
      }
    },
    updateEntityPart () {
      this.requesting = true
      EntityPartsRepository.update(this.entityPart.entityId, this.part.id, {
        stock_to_add: this.entityPart.quantity,
      })
        .then(({ data }) => {
          EventBus.$emit('addedStockToEntityPart', data)
          this.$store.commit('snackBar/setSnack', {
            color: 'primary',
            message: 'Stock Updated',
          })
          this.$emit('finish')
        })
    },
    createEntityPart () {
      this.requesting = true
      EntityPartsRepository.store(this.entityPart.entityId, {
        part_id: this.part.id,
        stock_in: this.entityPart.quantity,
      })
        .then(({ data }) => {
          EventBus.$emit('createdEntityPart', {
            partId: this.part.id,
            entityPart: data,
          })
          this.$store.commit('snackBar/setSnack', {
            color: 'primary',
            message: 'Parts added to entity',
          })

          this.$emit('finish')
        })
    },
  },
}
</script>

<style scoped>

</style>
